import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import DataTable from "../../../Components/DataGrid";
import { GET_CONFLICTS_DATA } from "../../../Graphql/queries/GET_CONFLICTS_DATA";
import dayjs from "dayjs";
import { useInquiryTarget } from "../../../hooks/useInquiryTarget";
import { useLazyQuery } from "@apollo/client";

export default function ConflictsTab() {
    const { inquiryTarget } = useInquiryTarget();
    const [conflictsList, setConflictslist] = useState([]);
    const [clientMatterState, setClientMatterState] = useState(null);

    const [getConflictsData, { loading }] = useLazyQuery(
        GET_CONFLICTS_DATA,
        {
            variables: clientMatterState,
            onCompleted: (data) => {
            const conflictsData = data?.getConflicts;
            conflictsData ? setConflictslist(conflictsData.conflicts) : setConflictslist([]);
            }
        });


    useEffect(() => {
        if (inquiryTarget) {
            let { client, matter } = inquiryTarget;
    
            //if at least client is selected, call the API
            if (client) {
                setClientMatterState({
                    clientId: parseInt(client.clientId),
                    jobId: matter ? parseInt(matter.matterId) : 0, //if no matter is selected, pass O as a jobId
                });
            }
        }
    }, [inquiryTarget]);

    useEffect(() => {
        clientMatterState && getConflictsData();
    }, [clientMatterState]);

    return (
        <Box className="tab-content">
            <Box className="content-header">
                <Typography variant="h6" paddingLeft={1}>
                    Conflict Summary
                </Typography>
            </Box>
            <Box>
                <DataTable rows={conflictsList} columns={columns} loading={loading}/>
            </Box>
        </Box>
    );
}
const columns = [
    {
        field: "id",
        headerName: "ID",
        width: 80
    },
    {
        field: "conflictName",
        headerName: "Conflict Name",
        width: 140
    },
    {
        field: "remarks",
        headerName: "Remarks",
        width: 110
    },
    {
        field: "requestor",
        headerName: "Requestor",
        width: 120
    },
    {
        field: "searchTerms",
        headerName: "Search Terms",
        width: 140
    },
    {
        field: "searched",
        headerName: "Searched",
        valueGetter: (params) => params.row.searched ? "Yes" : "No",
        width: 110
    },
    {
        field: "searchedBy",
        headerName: "Searched By",
        width: 130
    },
    {
        field: "searchedDate",
        headerName: "Searched Date",
        valueGetter: (params) =>
            dayjs(params.row.searchedDate).format('MM-DD-YYYY'),
        width: 140
    },
    {
        field: "hits",
        headerName: "Hits",
        width: 80
    },
    {
        field: "approverStatus",
        headerName: "Approver Status",
        width: 150
    },
    {
        field: "approverDecision",
        headerName: "Approver Decision",
        width: 165
    },
    {
        field: "finalDecision",
        headerName: "Final Decision",
        width: 140
    }
];
