import React, { useState, useEffect } from 'react';
import { Box, Typography, Tooltip, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DataTable from '../../../Components/DataGrid';
import './styles.scss';
import { useLazyQuery } from '@apollo/client';
import { GET_RECORDS } from '../../../Graphql/queries/GET_RECORDS';
import { useInquiryTarget } from "../../../hooks/useInquiryTarget";

const columns = [
  {
      field: 'typeCode',
      headerName: 'Type',
      minWidth: 160,
  },
  {
      field: 'code',
      headerName: 'Code',
      width: 180,
  },
  {
      field: 'reference',
      headerName: 'Reference',
      minWidth: 180,
  },
  {
      field: 'locationDescription',
      headerName: 'Location',
      minWidth: 180,
  },
  {
      field: 'status',
      headerName: 'Status',
      minWidth: 90,
  },
  {
      field: 'clientCode',
      headerName: 'Client',
      minWidth: 130,
  },
  {
      field: 'jobCode',
      headerName: 'Matter',
      minWidth: 130,
  },
  {
      field: 'description',
      headerName: 'Description',
      minWidth: 300,
  },

];
export default function RecordsTab() {
  const [recordsList, setRecordsList] = useState([]);
  const [openSearch, setOpenSearch] = React.useState(false);
  const { inquiryTarget } = useInquiryTarget();
  const [clientMatterState, setClientMatterState] = useState(null);
  
  const [fetchRecords, { loading }] =
    useLazyQuery(GET_RECORDS, {
      variables: clientMatterState,
      onCompleted: (data) => {
        const records = data?.getRecords?.records;
        if (records) {
          setRecordsList(records);
        } else {
          setRecordsList([]);
        }
      },
      onerror: (e) => {
        console.log(e);
      }
    });

  useEffect(() => {
    if (clientMatterState) {
      fetchRecords();
    }
  }, [clientMatterState]);

  useEffect(() => {
    if (inquiryTarget) {
      let { client, matter } = inquiryTarget;

      //if at least client is selected, call the API
      if (client) {
        setClientMatterState({
          clientId: parseInt(client.clientId),
          jobId: matter ? parseInt(matter.matterId) : null, //if no matter is selected, pass null as a jobId
        });
      } else {
        setRecordsList([]);
      }
    }
  }, [inquiryTarget]);
  
  

  return (
    <Box className="tab-content">
      <Box className="content-header">
        <Typography variant="h6" paddingLeft={1}>
          Record Management
        </Typography>
        <Box display="flex" alignItems="center">
          <Tooltip title={!openSearch ? "Show Filters" : "Hide Filters"}>
            <IconButton onClick={(event) => setOpenSearch(!openSearch)}>
              <SearchIcon className="MuiIcons" />
            </IconButton>
          </Tooltip>
          <Typography variant="body1" paddingLeft={1} paddingRight={1}>|</Typography>
          <Typography variant="body1">
          {recordsList.length} {recordsList.length === 1 ? 'Record' : 'Records'}
          </Typography>
        </Box>

      </Box>
      <Box>
        <DataTable rows={recordsList} columns={columns} loading={loading} />
      </Box>
    </Box>
  );
}
