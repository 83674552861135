import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import CustomPaginationExpenseGrid from "../CustomPagination//CustomPaginationExpenseGrid";
import FilterIcon from "@mui/icons-material/FilterList";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import "./style.scss";

export default function DataTable({ rows, columns, ...props }) {
  // eslint-disable-next-line no-unused-vars
  const handleFilter = (event, column) => {};
  return (
    <div className="custom-data-grid">
      <DataGrid
      {...props}
        data-testid="data-table"
        checkboxSelection={false}
        rows={rows}
        getRowId={(row) => row.Id || row.id}
        columns={columns.map((col) => ({
          ...col,
          headerClassName: "custom-header",
          renderHeader: (params) => (
            <div className="header-wrapper" style={{ display: "flex", width: "100%" }}>
              <div className="heading">
                {params.colDef.isCalcutateIcon && <CalculateOutlinedIcon />}
                {params.colDef.headerName}
              </div>

              <FilterIcon className="filter-icon" onClick={(event) => handleFilter(event, params.colDef)} />
            </div>
          ),
        }))}
        pagination={true}
        slots={{
          pagination: rows.length > 0 && CustomPaginationExpenseGrid,
        }}
        pageSizeOptions={[5, 10]}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 100, page: 0 },
          },
        }}
        disableColumnMenu={true}
        disableColumnSelector={true}
      />
    </div>
  );
}
